import "./styles/courseCard.css";
import React from "react";

interface ICourseCard {
  title: string;
  image: string;
  url: string;
}
export const CourseCard: React.FC<ICourseCard> = ({ title, image, url }) => {
  return (
    <div className="course-card">
      <a href={url}>
        <div className="card-content">
            <img className="card-image" src={image} alt=""/>
            <div className="card-title">
                <div>{title}</div>
            </div>
        </div>
      </a>
    </div>
  );
};
export const HiddenCourseCard: React.FC<ICourseCard> = ({ title, image, url }) => {
  return (
    <div className="hidden-course-card">
      <a href={url}>
        <div className="card-content">
            <img className="card-image" src={image} alt=""/>
            <div className="card-title">
                <div>{title}</div>
            </div>
        </div>
      </a>
    </div>
  );
};