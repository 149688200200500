import "./css/courses.css";
import { CourseCard, HiddenCourseCard } from "../components/layout/CourseCard";
import axios from "axios";
import { useEffect, useState } from "react";
import { User, onAuthStateChanged} from 'firebase/auth'
import { endpoint } from "../Services/Endpoint";
import { IsAdmin } from "../Services/IsAdmin";
import {auth} from '../firebase-config';
import ISection from "../interfaces/ISection";
import Loading from "../components/Loading";
import { useTranslation } from "react-i18next";
import { CaseInterface } from "../interfaces/prev/CaseInterface";
import ICategory from "../interfaces/ICategory";
import IUser, { ISubscription } from "../interfaces/IUser";
import ICard from "../interfaces/ICard";
import { Timestamp } from "firebase/firestore";
import { useNavigate } from "react-router-dom";
import { Modal } from "../components/Modal";
import { SimulatorModal } from "../components/layout/SimulatorModal";

export default function Courses() {
    const {t} = useTranslation();
    const [sections, setSections] = useState<ISection[] | null | any>([]);
    const [isAdmin, setIsAdmin] = useState(false);
    const [loading, setLoading] = useState(true);
    const [querySearch, setQuerySearch] = useState('');
    const [query, setQuery] = useState('');
    const [isSearch, setIsSearch] = useState(false);
    const [allCategories, setAllCategories] = useState<any[]>([]);
    const [allCases, setAllCases] = useState([]);
    const [casesFiltered, setCasesFiltered] = useState<CaseInterface[]>([]);
    const [categoriesFiltered, setCategoriesFiltered] = useState<string[]>([]);
    const [dataDownloaded, setDataDownloaded] = useState(false);
    const [userData, setUserData] = useState<IUser>();
    const [user, setUser] = useState<User|null>(null);
    const [isOpenLoginModal, setIsOpenLoginModal] = useState(false); 
    const [isOpenSubscriptionModal, setIsOpenSubscriptionModal] = useState(false); 
    const [isOpenSelectSimulatorModal, setIsOpenSelectSimulatorModal] = useState(false);
    const [selectedCase, setSelectedCase] = useState("");

    const navigate = useNavigate();

    const onLoadPage = async () => { 
        await axios.get(`${endpoint}/courses/sections`).then(result => setSections(result.data));
        onAuthStateChanged(auth, async(user)=>{
            if (user){
                setUser(user);
                await IsAdmin().then(response => setIsAdmin(response));
                axios.get(`${endpoint}/users/${user.uid}`).then((response) => {setUserData(response.data)});
            }
        });
        setLoading(false);
    };
    const toggleSubscriptionModal = ()=> setIsOpenSubscriptionModal(!isOpenSubscriptionModal);
    const toggleLoginModal = ()=> setIsOpenLoginModal(!isOpenLoginModal);
    const getData = () => {
        return new Promise(async(resolve, rejected) => {
            setLoading(true);
            try{ 
                const _cases = await axios.get(`${endpoint}/courses/cases`);
                await setAllCases(_cases.data)
                const _categories = await axios.get(`${endpoint}/courses/categories`);
                await setAllCategories(_categories.data);
             
                setDataDownloaded(true);
                resolve("Data descargada");
            } catch{
                rejected("Error al descargar la data");
            }
            setLoading(false);
        });
    }
    const lang = () => localStorage.getItem("lan") ?? "en";
    const closeSearch = () => {
        setIsSearch(false);
        setQuerySearch('');
    }
    const onSearch = async()=>{
        if(querySearch === '') 
            return;
        
        setIsSearch(true);

        if(!dataDownloaded){
            await getData();
        } else {
            filterData();
        }
    }
    const filterData = () => {
        if(dataDownloaded) {
            setQuery(querySearch);
            let sectionsPublished = sections.filter((section:any) => section.visibility).map((section:any) => (section.id));
            let categoriesPublished:string[] = allCategories.filter((category:any) => sectionsPublished?.includes(category.section)).map((category:ICategory) => (category.id));
            let casesPublished = allCases.filter((_case:CaseInterface) => categoriesPublished?.includes(_case.category));
            let _casesFiltered = casesPublished.filter((_case:CaseInterface) => { 
                let fieldsToSearch = [ _case.title, _case.hiddenTitle, _case.description, _case.descriptionPreview ];
                return fieldsToSearch.some((field:any) => normalizeWord(field[lang()])?.includes(normalizeWord(querySearch)));
            });
            let _categoriesFiltered = _casesFiltered.map((_case:CaseInterface) => _case.category).filter((valor, indice, array) => array.indexOf(valor) === indice);

            setCategoriesFiltered(_categoriesFiltered);
            setCasesFiltered(_casesFiltered);
        }
    }
    const normalizeWord = (word: string): string => {
        let normalizedWord = word;
        let result;
        try {    
            if (/[\u0300-\u036f]/.test(word)) {
                normalizedWord = word.normalize("NFD").replace(/[\u0300-\u036f]/g, "");
            }
            result = normalizedWord.toLocaleLowerCase();
        } catch (error) {
            result = word;
            console.error("Error normalizing word:", word, error);
        }
        return result;
    };

    const openCase = async (_case: CaseInterface, sectionId:string) => {
        if(typeof user?.uid === 'undefined'){            
            setIsOpenLoginModal(true);
        } else if(typeof userData !== 'undefined') {
            let pricingRequired:ICard[] = (await axios.get(`${endpoint}/pricing`)).data.filter((x:any) => { return x?.sections?.includes(sectionId) });
            let subscriptionsActive:ISubscription[]|any = userData?.subscriptions?.filter((x) => x.next_billing_time.seconds > Timestamp.now().seconds);
            let canCourse = userData?.isAdmin || userData?.isPremium || isSubscribed(pricingRequired, subscriptionsActive);

            if(canCourse){
                setSelectedCase(_case?.id);
                setIsOpenSelectSimulatorModal(true);
            } else {
                setIsOpenSubscriptionModal(true);
            }
        } else {
            setLoading(true);
            axios.get(`${endpoint}/users/${user.uid}`).then((response) => {
                setUserData(response.data); 
                setLoading(false);}
            ).catch((error) => console.error(error));
        }
    }
    const isSubscribed = (array1:ICard[], array2:ISubscription[]) => {
        if(typeof array1 === "undefined" || typeof array2 === "undefined")
            return false;
        
        for (const element1 of array1) {
            for (const element2 of array2) {
                if (element1.id === element2.product_id) {
                    return true;
                }
            }
        }
        return false;
    }
    const goToPricing = ()=> {
        navigate('/pricing', { replace: true });
    }
    
    const goToLogin = ()=> {
        navigate('/login', { replace: true });
    }
    useEffect(() => { onLoadPage(); }, []);
    useEffect(filterData, [dataDownloaded]);

    const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
        if (event.key === 'Enter') {
            onSearch();
        }
      };

    if(loading) return <Loading/>
    return (
        <main className="courses">
        <div className="searcher">
            <input type="text" placeholder={t("Courses.SearchField")} value={querySearch} onChange={(e) => { setQuerySearch(e.currentTarget.value) }} onKeyDown={handleKeyDown}/>
            <input className="bg-blue" type="button" value={t("Courses.Search")} onClick={onSearch} />
            {isSearch && <input className="bg-red" type="button" value={"Cerrar busqueda"} onClick={closeSearch}/>}
        </div>
        {
            !isSearch && <>
                <h1> { t("Courses.Courses") } </h1>
                <section className="all-courses">
                    {
                        sections.filter((x: any) => x.visibility).map((x: any) => (
                            <CourseCard title={x.title[lang()] ?? x.title["es"]} image={x.image} url={`/courses/${x.id}/`} />
                        ))
                    }
                </section>
                {isAdmin && <h1>{ t("Courses.ComingSoon") }</h1>}
                <section className="all-courses">
                    {
                        isAdmin && sections.filter((x: any) => !x.visibility).map((x: any) => (
                            <HiddenCourseCard title={x.title[lang()] ?? x.title["es"]} image={x.image} url={`/courses/${x.id}/`} />
                        ))
                    }
                </section>
            </>
        }
        {
            isSearch && <div className="searchResult">
                <p className="searchTitle">{t("Courses.Results")} "<span>{query}</span>"</p>
                {
                    categoriesFiltered.map((x:string) => (
                        casesFiltered?.filter((y)=> {return y.category === x && (y.visibility || isAdmin) }).length > 0 && <div className='categories'>
                            <div className='category-data'>  
                                <a href={`/courses/${allCategories.find((_cat => _cat.id === x))?.section}`}>
                                    { allCategories.find(_category => _category.id === x)?.title[lang()] ?? allCategories.find(_category => _category.id === x)?.title["es"] } 
                                    <span>({ sections.find((s:ISection) => s.id === allCategories.find((_cat => _cat.id === x))?.section).title[lang()] ?? sections.find((s:ISection) => s.id === allCategories.find((_cat => _cat.id === x))?.section).title["es"]})</span>
                                </a> 
                            </div>
                            <div className='courses-carousel'>
                                {
                                    casesFiltered?.filter((y)=> {return y.category === x && (y.visibility || isAdmin) }).map((z:CaseInterface|any) => (
                                        <div style={{ backgroundImage: `url(${process.env.PUBLIC_URL + '/default-case.png'})`}} className="case-card" onClick={()=> {openCase(z, sections.find((s:ISection) => s.id === allCategories.find((_cat => _cat.id === x))?.section).id)}}>
                                            <div className='case-content'>
                                                { !z.visibility && <span>Admin</span> }
                                                <div className='case-data'>
                                                    <span className='case-title'>{ z?.title[lang()] ?? z.title["es"]}</span>
                                                    <span className='case-description'>{ z.descriptionPreview[lang()] ?? z.descriptionPreview["es"] }</span>
                                                </div>
                                            </div>
                                        </div>
                                    ))
                                }
                            </div>
                        </div>
                    ))
                }
            </div> 
        }
        <Modal title= {t("Modals.Subscription.Title")}  isOpen={isOpenSubscriptionModal} onClose={toggleSubscriptionModal}>
            <div className="modal-content">
                <span>{t("Modals.Subscription.Description")}</span>
                <input type='button' value={t("Modals.Subscription.Button")} onClick={goToPricing}/>
            </div>
        </Modal>
        <Modal title={t("Modals.Login.Title")} isOpen={isOpenLoginModal} onClose={toggleLoginModal}>
            <div className="modal-content">
                <span>{t("Modals.Login.Description")}</span>
                <input type='button' value={t("Modals.Login.Button")} onClick={goToLogin}/>
            </div>
        </Modal>
        <SimulatorModal _case = {selectedCase} lang={lang()} userId={user?.uid} isOpenSelectSimulatorModal = {isOpenSelectSimulatorModal} onCloseModal={()=>{setIsOpenSelectSimulatorModal(false)}}/>
        </main>
    );
}