import { useTranslation } from 'react-i18next'
import './css/admin.css'
import { useEffect, useState } from 'react';
import { User, onAuthStateChanged } from 'firebase/auth';
import { auth } from '../firebase-config';

export default function Admin() {   
    const {t} = useTranslation();

    const [user, setUser] = useState<User|null>(null);
    const onLoadPage = ()=> {
        onAuthStateChanged(auth, async(user)=>{
            if (user){
                setUser(user);
            }
        });
        
    }
    useEffect(()=>{onLoadPage()}, []);
    return(
        <main className="admin">
            <div className="button"><a href="/admin-cases">{t("Admin.Cases")}</a></div>
            <div className="button"><a href="/admin-categories">{t("Admin.Categories")}</a></div>
            <div className="button"><a href="/admin-sections">{t("Admin.Sections")}</a></div>
            <div className="button"><a href="/admin-edit-users">{t("Admin.Users")}</a></div>
            <div className="button"><a href="/sales">{t("Admin.Sales")}</a></div>
            <div className="button"><a href="/reports">{t("Admin.Reports")}</a></div>
        </main>
    )
}