import { useState, useEffect } from "react";
import Card from "../components/layout/Card";
import "./css/pricing.css";
import { auth } from "../firebase-config";
import { onAuthStateChanged } from "firebase/auth";
import { IsAdmin } from "../Services/IsAdmin";
import { MultiSelect } from "react-multi-select-component";
import axios from "axios";
import { endpoint } from "../Services/Endpoint";
import ISection from "../interfaces/ISection";
import {Option} from 'react-multi-select-component'
import ICard from "../interfaces/ICard";
import Loading from "../components/Loading";
import { useTranslation } from "react-i18next";

export default function Pricing() {
  const {t} = useTranslation();
  const [isAdmin, setIsAdmin] = useState(false);
  const [sectionsOptions, setSectionsOptions] = useState<Option[]>([]);
  const [prices, setPrices] = useState<ICard[]>([]);
  const [newPricing, setNewPricing] = useState({});
  const [selected, setSelected] = useState([]);
  const [loading, setLoading] = useState(true);

  const onLoadPage = async () => {
    await axios.get(`${endpoint}/courses/sections`).then((result)=>{ setSectionsOptions(result.data.map((x:any) => convertObj(x)));}).catch(error => console.error(error));
    await axios.get(`${endpoint}/pricing`).then((result) => setPrices(result.data)).catch((error) => console.error(error));
    
    onAuthStateChanged(auth, async(user)=>{
      if (user)
        await IsAdmin().then(response => setIsAdmin(response))
      setLoading(false);
    });
  }
  const Lang = () => localStorage.getItem("lan") ?? "en";
  const onChangeData = (name:string, value: any) => {
    let prevData:any = { ...newPricing };
    prevData[name] = value;
    setNewPricing(prevData);
  }
  const onChangeDataMultiLanguage = (name:string, value:any) => {
    let prevData: any = { ...newPricing };
    if (!prevData[name])
      prevData[name] = {};

    prevData[name][Lang()] = value;
    setNewPricing(prevData);
  }
  const convertObj = (obj:ISection) => {
    return {
      label: obj.title.es,
      value: obj.id,
      disabled: !obj.visibility
    }
  }
  
  const savePricing = () => { 
    axios.post(`${endpoint}/pricing`, newPricing).then(() => window.location.reload()).catch((error) => console.error(error)); 
  }
  const contactUs = ()=> {
    window.location.href = 'mailto:educacion@medicinafetalmexico.com'
  }
  
  useEffect(() => { onLoadPage(); }, []);
  useEffect(()=> { onChangeData("sections", selected.map((x:any) => x.value)); }, [selected]);

  if(loading) return <Loading/>
  return (
    <main className="pricing">
      {prices.map(({ id, title, price, monthDuration, description1, description2, visibility, sections }) => (
        (visibility || isAdmin) && 
        <Card id={id} title={title } price={price} monthDuration={monthDuration} description1={description1} description2={description2} visibility = {visibility} 
        sections={ sectionsOptions?.filter((x:Option) => { return sections?.includes(x.value) }) } />
      ))}
      
      <div className="card">
        <div className="info">
          <span className="title">{t("Pricing.Educational.Educational")}</span>
          <span className="price">{t("Pricing.Educational.LetsTalk")}</span>
          <br/>
          <span className="type">{t("Pricing.Educational.Description1")}</span>
          <span className="description">{t("Pricing.Educational.Description2")}</span>
          <input className="get-button" type="button" value={t("Pricing.ContactUs")} onClick={contactUs} />
        </div> 
      </div> 
      
      { 
        isAdmin && <div className="card-pricing">
          <div className="card-data">
            <p>{t("Pricing.Create.Header")}</p>
            <input type="text" placeholder={t("Pricing.Create.Title")} name="title" onChange={(e) => onChangeDataMultiLanguage(e.target.name, e.target.value)} />
            <input type="number" placeholder={t("Pricing.Create.Price")} name="price" onChange={(e) => onChangeData(e.target.name, e.target.value)}/>
            <input type="number" placeholder={t("Pricing.Create.Duration")} name="monthDuration" onChange={(e) => onChangeData(e.target.name, e.target.value)}/>
            <input type="text" placeholder={t("Pricing.Create.Description1")} name="description1" onChange={(e) => onChangeDataMultiLanguage(e.target.name, e.target.value)}/>
            <input type="text" placeholder={t("Pricing.Create.Description2")} name="description2" onChange={(e) => onChangeDataMultiLanguage(e.target.name, e.target.value)}/>
          
            <p>{t("Pricing.Create.SelectSections")}</p>
            <MultiSelect options={sectionsOptions} value={selected} onChange={setSelected} labelledBy="Select"/>

            <div className="row center">
              <p>{t("Pricing.Create.Visibility")}:</p>
              <input type="checkbox" name="visibility" value={"Visible"} onChange={(e) => onChangeData(e.target.name, e.target.checked)} />
            </div>
          </div>
          <div className="card-buttons">
            <input type="button" value={t("Pricing.Create.Submit")} onClick={savePricing} />
          </div>
      </div>
      }
    </main>
  );
}
