import { Routes, Route, BrowserRouter} from 'react-router-dom'
import { Layout } from './components/layout/Layout';
import './App.css';
import NewHome from './pages/NewHomePage'
import NotFound from './pages/NotFoundPage';
import Courses from './pages/CoursesPage';
import CourseTemplate from './pages/CourseTemplatePage';
import Pricing from './pages/PricingPage';
import FAQs from './pages/FAQsPage';
import Login from './pages/LoginPage';
import Signup from './pages/SingupPage';
import PasswordRecovery from './pages/PasswordRecoveryPage';
import Admin from './pages/AdminPage';
import Cases from './pages/Cases';
import Categories from './pages/CategoriesPage';
import Sections from './pages/SectionsPage';
import EditUsers from './pages/EditUsersPage';
import Privacy from './pages/PrivacyPage';
import Terms from './pages/TermsPage';
import { RequireAdmin } from './Services/RequireAdmin';
import { Profile } from './pages/ProfilePage';
import Report from './pages/ReportsPage';
import Sales from './pages/SalesPage';
import ViewUsers from './pages/ViewUsersPage';
import { RequireUser } from './Services/RequireUser';
import Partner from './pages/PartnerPage';
import { RequirePartner } from './Services/RequirePartner';
import Learning from './pages/LearningPage';

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path='/' element={<Layout/>}>
          <Route index element={<NewHome/>} />
          <Route path='login' element={<Login/>}/>
          <Route path='signup' element={<Signup/>}/>
          <Route path='profile' element={<RequireUser><Profile/></RequireUser>}/>
          <Route path='password-recovery' element={<PasswordRecovery/>}/>
          <Route path='courses' element={<Courses/>}/>
          <Route path='fuve-learning' element={<Learning/>}/>
          <Route path='courses/:id' element={<CourseTemplate/>}/>
          <Route path='pricing' element={<Pricing/>}/>
          <Route path='faqs' element={<FAQs/>}/>
          <Route path='admin' element={ <RequireAdmin><Admin/></RequireAdmin> }/>
          <Route path='partner' element={ <RequirePartner><Partner/></RequirePartner> }/>
          <Route path='admin-cases' element={<RequireAdmin><Cases/></RequireAdmin>}/>
          <Route path='admin-categories' element={<RequireAdmin><Categories/></RequireAdmin>}/>
          <Route path='admin-sections' element={<RequireAdmin><Sections/></RequireAdmin>}/>
          <Route path='view-users' element={<RequireAdmin><ViewUsers/></RequireAdmin>}/>
          <Route path='admin-edit-users' element={<RequireAdmin><EditUsers/></RequireAdmin>}/>
          <Route path='sales' element={<RequireAdmin><Sales/></RequireAdmin>}/>
          <Route path='reports' element={<RequireAdmin><Report/></RequireAdmin>}/>
          <Route path='terms' element={<Terms/>}/>
          <Route path='privacy' element={<Privacy/>}/>
          <Route path="*" element={<NotFound />} />
        </Route>
      </Routes>
    </BrowserRouter>
  );
}

export default App;